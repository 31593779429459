import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { signMessage } from "@/helpers/sign-message";
import { useWeb3Store } from "@/store/web3";

export const godApeQuestChooseTeam = async (team) => {
  const signature = await signMessage("I'm selecting a quest team");
  if (!signature) return;
  const res = await useApesStorageApi({ toast: { error: true } }).exec({
    url: "/quest/choose-team",
    method: "POST",
    data: {
      wallet: useWeb3Store().collectionWallet,
      team, // ava / vyl
      signature,
      network: Config.network.name,
    },
  });
  return res;
};
