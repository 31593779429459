<template>
    <AModalContent class="god-ape-quest-start-modal">
        <div class="god-ape-quest-start-video" v-if="step === 1">
            <video autoplay @ended="onVideoEnd">
                <source :src="videoUrl" type="video/mp4">
            </video>
        </div>
        <div class="god-ape-quest-start-choice" v-if="step === 2">
            <GodApeQuestTeamChoice @choose="chooseTeam" />
        </div>
        <div class="god-ape-quest-start-team-intro" v-if="step === 3 || step === 4">
            <video @ended="onTeamIntroEnd" autoplay>
                <source :src="teamVideoUrl" type="video/mp4">
            </video>
            <div class="god-ape-quest-start-end">
                <div class="god-ape-quest-start-end__info">
                    <div class="god-ape-quest-start-end__title">CONGRATULATIONS!</div>

                    <div
                        class="god-ape-quest-start-end__text"
                    >
You’re a part of {{ currentTeam === 'ava' ? 'Ava’s Pride' : 'Vyl’s Army' }}.
</div>
                    <a
                        href="javascript:void(0)"
                        class="god-ape-quest-start-end__button"
                        @click.prevent="continueQuest"
                        v-if="step === 4"
                    >Continue your Quest</a>
                </div>
            </div>
        </div>
    </AModalContent>
</template>
<script setup>
import { computed, ref } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";
import GodApeQuestTeamChoice from "../GodApeQuest/GodApeQuestTeamChoice.vue";
import { godApeQuestChooseTeam } from "@/functions/god-ape-quest/god-ape-quest-choose-team";
import { useRouter } from "vue-router";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
const emit = defineEmits(['close'])
const step = ref(1)
const videoUrl = '/god-ape-quest-intro.mp4'
const currentTeam = ref('')
const router = useRouter()
const teamVideoUrl = computed(() => {
    if (currentTeam.value === 'ava') {
        return require('@/assets/video/god-ape-quest/team-ava-intro.mp4')
    }
    return require('@/assets/video/god-ape-quest/team-vyl-intro.mp4')

})
const onVideoEnd = () => {
    step.value = 2
}
const chooseTeam = async (team) => {
    const success = await godApeQuestChooseTeam(team)
    if (!success) return
    currentTeam.value = team
    step.value = 3
    // updating team in the store
    useGodApeQuestStore().init(true)
}
const onTeamIntroEnd = () => {
    step.value = 4
}


const continueQuest = () => {
    emit('close')
    router.push({ name: "Formulas", hash: "#god_ape_quest" })
}

</script>
<style lang="scss">
.god-ape-quest-start {
    &-modal {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        video {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: auto;
        }
    }
    &-video {
    }
    &-choice {
    }
    &-team-intro {
        position: relative;
        height: 100%;
        width: 100%;
        video {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
    &-end {
        text-align: center;
        box-sizing: border-box;
        border-radius: 10px;
        padding-top: 80px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &__info {
            background: rgba(0, 0, 0, 0.5);
            padding: 10px;
        }
        &__title {
            font-size: 30px;
            color: var(--ape-quest);
            font-weight: bold;
            margin-bottom: 10px;
        }
        &__text {
            font-size: 22px;
        }
        &__button {
            width: 80%;
            height: 80px;
            box-sizing: border-box;
            font-size: 20px;
            background: var(--ape-quest);
            padding: 10px 20px;
            color: #fff;
            border-radius: 5px;
            font-family: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            transition: 0.2s;
            margin: 0 auto;
            margin-top: 40px;
            &:hover {
                filter: brightness(0.8);
            }
        }
    }
    @media screen and (max-width: 576px) {
        &-end {
            &__button {
                height: 50px;
            }
        }
    }
}
</style>